import Vue from 'vue'
import VueRouter from 'vue-router'
// import { store } from '../store'

const BaseView = () => import('@/views/BaseView')

/**
 * Telekom start
 *
 * @returns {Promise<*>|*}
 * @constructor
 */
const StartIndexView = () => import('@/views/start/Index')
const StartView = () => import('@/views/start/Start')

/**
 * Telekom 5g
 *
 * @returns {Promise<*>|*}
 * @constructor
 */
const IntroOneIndexView = () => import('@/views/intro/Index')
const SlidesOneView = () => import('@/views/intro/Slides')

/**
 * Telekom 5g Intro 2
 *
 * @returns {Promise<*>|*}
 * @constructor
 */
const IntroTwoIndexView = () => import('@/views/intro2/Index')
const SlidesTwoView = () => import('@/views/intro2/Slides')

/**
 * Telekom 5g Intro 3
 *
 * @returns {Promise<*>|*}
 * @constructor
 */
const IntroThreeIndexView = () => import('@/views/intro3/Index')
const SlidesThreeView = () => import('@/views/intro3/Slides')

/**
 * Telekom 5g Intro 4
 *
 * @returns {Promise<*>|*}
 * @constructor
 */
const IntroFourIndexView = () => import('@/views/intro4/Index')
const SlidesFourView = () => import('@/views/intro4/Slides')

/**
 * Telekom 5g Intro 5
 *
 * @returns {Promise<*>|*}
 * @constructor
 */
const InfoFiveIndexView = () => import('@/views/intro5/Index')
const SlidesFiveView = () => import('@/views/intro5/Slides')

/**
 * Telekom 5g Intro 6
 *
 * @returns {Promise<*>|*}
 * @constructor
 */
const InfoSixIndexView = () => import('@/views/intro6/Index')
const SlidesSixView = () => import('@/views/intro6/Slides')

/**
 * Telekom 5g Intro 7
 *
 * @returns {Promise<*>|*}
 * @constructor
 */
const InfoSevenIndexView = () => import('@/views/intro7/Index')
const SlidesSevenView = () => import('@/views/intro7/Slides')

/**
 * Telekom 5g Intro 8
 *
 * @returns {Promise<*>|*}
 * @constructor
 */
const InfoEightIndexView = () => import('@/views/intro8/Index')
const SlidesEightView = () => import('@/views/intro8/Slides')

/**
 * Telekom 5g Intro 9
 *
 * @returns {Promise<*>|*}
 * @constructor
 */
const InfoNineIndexView = () => import('@/views/intro9/Index')
const SlidesNineView = () => import('@/views/intro9/Slides')

/**
 * Telekom 5g Intro 10
 *
 * @returns {Promise<*>|*}
 * @constructor
 */
const InfoTenIndexView = () => import('@/views/intro10/Index')
const SlidesTenView = () => import('@/views/intro10/Slides')

/**
 * Telekom 5g Intro 11
 *
 * @returns {Promise<*>|*}
 * @constructor
 */
const InfoElevenIndexView = () => import('@/views/intro11/Index')
const SlidesElevenView = () => import('@/views/intro11/Slides')

/**
 * Static Page (datenschutz)
 *
 * @returns {Promise<*>|*}
 * @constructor
 */
const StaticPageIndexView = () => import('@/views/staticPage/Index')
const StaticPageView = () => import('@/views/staticPage/StaticPage')

/**
 * Telekom 5g Intro 12 Speed Check
 *
 * @returns {Promise<*>|*}
 * @constructor
 */
const InfoSpeedCheckIndexView = () => import('@/views/intro12-speed-check/Index')
const SlidesSpeedCheckView = () => import('@/views/intro12-speed-check/Slides')

/**
 * Telekom 5g Intro 13
 *
 * @returns {Promise<*>|*}
 * @constructor
 */
const InfoThirteenIndexView = () => import('@/views/intro13/Index')
const SlidesThirteenView = () => import('@/views/intro13/Slides')

/**
 * Telekom 5g Intro 13 Clone
 *
 * @returns {Promise<*>|*}
 * @constructor
 */
const InfoThirteenCloneIndexView = () => import('@/views/intro13-clone/Index')
const SlidesThirteenCloneView = () => import('@/views/intro13-clone/Slides')

/**
 * Telekom 5g Intro 14 Streaming Calculator
 *
 * @returns {Promise<*>|*}
 * @constructor
 */
const StreamingCalculatorIndexView = () => import('@/views/intro14-streaming-calculator/Index')
const StreamingCalculatorView = () => import('@/views/intro14-streaming-calculator/Slides')

/**
 * Telekom 5g Intro 15 Hybrid
 *
 * @returns {Promise<*>|*}
 * @constructor
 */
const SlidesHybridIndexView = () => import('@/views/intro16-hybrid/Index')
const SlidesHybridView = () => import('@/views/intro16-hybrid/Slides')

/**
 * Telekom 5g Intro 17 GK
 *
 * @returns {Promise<*>|*}
 * @constructor
 */
const SlidesGkIndexView = () => import('@/views/intro18-gk/Index')
const SlidesGkView = () => import('@/views/intro18-gk/Slides')

Vue.use(VueRouter)

const routes = [
  {
    path: process.env.VUE_APP_PUBLIC_PATH,
    component: BaseView,
    children: [
      {
        path: '',
        component: StartIndexView,
        children: [
          {
            path: '/',
            name: 'Start',
            component: StartView
          }
        ]
      },
      {
        path: 'slidesone/:id?',
        component: IntroOneIndexView,
        children: [
          {
            path: '/',
            name: 'Intro',
            component: SlidesOneView
          }
        ]
      },
      {
        path: 'slidestwo/:id?',
        component: IntroTwoIndexView,
        children: [
          {
            path: '/',
            name: 'IntroTwo',
            component: SlidesTwoView
          }
        ]
      },
      {
        path: 'slidesthree/:id?',
        component: IntroThreeIndexView,
        children: [
          {
            path: '/',
            name: 'IntroThree',
            component: SlidesThreeView
          }
        ]
      },
      {
        path: 'slidesfour/:id?',
        component: IntroFourIndexView,
        children: [
          {
            path: '/',
            name: 'IntroFour',
            component: SlidesFourView
          }
        ]
      },
      {
        path: 'slidesfive/:id?/:subId?',
        component: InfoFiveIndexView,
        children: [
          {
            path: '/',
            name: 'IntroFive',
            component: SlidesFiveView
          }
        ]
      },
      {
        path: 'slidessix/:id?/:slideId?',
        component: InfoSixIndexView,
        children: [
          {
            path: '/',
            name: 'IntroSix',
            component: SlidesSixView
          }
        ]
      },
      {
        path: 'slidesseven/:id?/:slideId?',
        component: InfoSevenIndexView,
        children: [
          {
            path: '/',
            name: 'IntroSeven',
            component: SlidesSevenView
          }
        ]
      },
      {
        path: 'slideseight/:id?',
        component: InfoEightIndexView,
        children: [
          {
            path: '/',
            name: 'IntroEight',
            component: SlidesEightView
          }
        ]
      },
      {
        path: 'slidesnine/:id?',
        component: InfoNineIndexView,
        children: [
          {
            path: '/',
            name: 'IntroNine',
            component: SlidesNineView
          }
        ]
      },
      {
        path: 'slidesten/:id?',
        component: InfoTenIndexView,
        children: [
          {
            path: '/',
            name: 'IntroTen',
            component: SlidesTenView
          }
        ]
      },
      {
        path: 'slideseleven/:id?',
        component: InfoElevenIndexView,
        children: [
          {
            path: '/',
            name: 'IntroEleven',
            component: SlidesElevenView
          }
        ]
      },
      // {
      //   path: 'datenschutz',
      //   component: StaticPageIndexView,
      //   children: [
      //     {
      //       path: '/',
      //       name: 'StaticPage',
      //       component: StaticPageView
      //     }
      //   ]
      // },
      {
        path: 'speed-check',
        component: InfoSpeedCheckIndexView,
        children: [
          {
            path: '/',
            name: 'SpeedCheck',
            component: SlidesSpeedCheckView
          }
        ]
      },
      {
        path: 'slidesthirteen/:id?/:slideId?',
        component: InfoThirteenIndexView,
        children: [
          {
            path: '/',
            name: 'IntroThirteen',
            component: SlidesThirteenView
          }
        ]
      },
      {
        path: 'sample-slide/:id?/:slideId?',
        component: InfoThirteenCloneIndexView,
        children: [
          {
            path: '/',
            name: 'IntroThirteenSample',
            component: SlidesThirteenCloneView
          }
        ]
      },
      {
        path: 'streaming-calculator/:slug?',
        component: StreamingCalculatorIndexView,
        children: [
          {
            path: '/',
            name: 'StreamingCalculator',
            component: StreamingCalculatorView
          }
        ]
      },
      {
        path: 'slides-hybrid/:id?',
        component: SlidesHybridIndexView,
        children: [
          {
            path: '/',
            name: 'IntroHybrid',
            component: SlidesHybridView
          }
        ]
      },
      {
        path: 'slides-gk/:id?/:slideId?',
        component: SlidesGkIndexView,
        children: [
          {
            path: '/',
            name: 'IntroGk',
            component: SlidesGkView
          }
        ]
      },
      {
        path: '*',
        component: StaticPageIndexView,
        children: [
          {
            path: '/',
            name: 'StaticPage',
            component: StaticPageView
          }
        ]
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      if (savedPosition) {
        // History back position, if user click Back button
        resolve(savedPosition)
      } else {
        // Scroll to top of page if the user didn't press the back button
        resolve({ x: 0, y: 0 })
      }
      // resolve({ x: 0, y: 0 })
    })
  }
})
router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || 'Experience'
  })
})
// router.beforeEach((to, from, next) => {})

export default router
